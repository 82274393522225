import React from 'react';
import { FaRegCircle } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";

const Workflows = [
  {
    title: "Task #1",
    list: "Sed dapibus tincidunt lorem quis egestas.",
    date: "Nov 23, 2023",
    time: "09:00 AM",
    link: "workflows",

  },
  {
    title: "Task #2",
    list: "Sed dapibus tincidunt lorem quis egestas.",
    date: "Nov 23, 2023",
    time: "11:45 PM",
    link: "workflows/weather-update",

  },

  {
    title: "Task #3",
    list: "Sed dapibus tincidunt lorem quis egestas.",
    date: "Nov 23, 2023",
    time: "11:45 PM",
    link: "workflows/online-shopping",

  },
  {
    title: "Task #4",
    list: "Sed dapibus tincidunt lorem quis egestas.",
    date: "Nov 23, 2023",
    time: "09:00 AM",
    link: "workflows/social-media-recap",

  },

]

const PendingTask = () => {
  return (
    <div className="flex flex-col gap-8 lg:flex-row justify-stretch items-center lg:gap-14 ">

      <div className="grid grid-cols-1 md:grid-cols-2 mt-2 mb-2 w-full gap-2 md:gap-4 ">

        {Workflows.map((wflows, i) => {
          return (

            <>

              <div className='flex flex-col justify-between items-start gap-4 shadow-md p-2 border-[1px] border-[#E2E8F0] rounded-[14px] bg-lime-100'>

                <div className='flex items-start justify-center font-bold text-black text-[20px] p-4' >
                  {wflows.title}
                </div>

                <div className='flex flex-row justify-center items-center gap-3 w-full'>

                  <div className='font-bold text-[22px]'>
                    <FaRegCircle />
                  </div>

                  <ul>
                    <li className="text-[#A1A1A1] text-normal text-[16px]">{wflows.list}</li>
                  </ul>

                </div>

                <div className="flex flex-row justify-center items-center w-full rounded-full bg-white p-2 gap-3">

                  <div className='font-bold'>
                    <SlCalender />
                  </div>

                  <div className='font-semibold'>
                    {wflows.date}
                  </div>
                  <div className='font-medium'>
                    {wflows.time}
                  </div>

                </div>



              </div>




            </>

          );
        })}

      </div>


    </div>
  );
};


export default PendingTask
