import React from 'react';
import background from './images/balbg.png';

const PointsBox = () => {
    return (
        <div className="flex flex-col rounded-[15px] border border-[#D7D7D7] w-full lg:w-[30%] p-6 bg-[#5A3472] gap-8 justify-between items-start h-auto "
            style={{
                backgroundImage: `url(${background})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',

            }} >

            <div className='flex flex-col  justify-between items-start gap-2 p-2 w-[60%] rounded-2xl bg-violet-600'>

                <h1 className="text-white text-[14px] font-bold font-['Mona Sans']">Points Earned:</h1>
                <span className='text-white'>10,000</span>

            </div>



            <div className='flex flex-col justify-between items-start w-full gap-2'>

                <h3 className="text-white text-[17px] font-normal font-['Mona Sans']">Great Job, You Saved</h3>

                <h1 className=" text-white text-[40px] font-bold font-['Mona Sans']">$ 3534.58</h1>


            </div>



        </div>
    )
}

export default PointsBox