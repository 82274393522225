import React from 'react';
import background from './images/eventbg.png';
import { MdEventNote } from "react-icons/md";

const events = [
    {
        path: "/details",
        title: "Design Team Meeting - Discuss New Wireframes",
        time: "11 : 30 AM - 01 : 00 PM",


    },
    {
        path: "/details",
        title: "Design Team Meeting - Discuss New Wireframes",
        time: "11 : 30 AM - 01 : 00 PM",


    },
]



const EventsBox = () => {
    return (
        <div className="flex flex-col rounded-[15px] border border-[#D7D7D7] w-full lg:w-[65%] p-6 bg-[#5A3472] gap-8 justify-between items-start h-auto sm:h-[400px]"
            style={{
                backgroundImage: `url(${background})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',

            }} >

            <div className='flex flex-col sm:flex-row justify-between items-center gap-2 sm:gap-8 w-full'>

                <div className='rounded-full px-4 py-2 shadow-md border-[2px] border-white'>
                    <button className="text-white text-[16px] font-bold font-['Mona Sans']">Your Events</button>
                </div>

                <div className='flex flex-row justify-center items-center rounded-full p-2 shadow-md border-[1px] border-white bg-white'>

                    <div className='text-[20px] font-medium'>
                        <MdEventNote />
                    </div>

                    <h1 className='text-black text-[15px]  font-bold p-1 '>Sat , 2 Dec, 2023</h1>
                </div>
            </div>



            <div className='flex flex-col justify-between items-start w-full rounded-[10px] bg-white p-4 gap-2'>
                <div>
                    <h1 className="text-zinc-500 text-xs font-bold font-['Mona Sans']">Upcoming Events</h1>
                </div>

                <div className='flex flex-col lg:flex-row justify-center items-center gap-4'>
                {events.map((data, i) => {
                    return (
                        <>
                            <div className='flex flex-row justify-between items-start gap-4 rounded-lg shadow-xl border-l-4 border-orange-400' >      

                                <div className='flex flex-row gap-2 justify-start items-start w-full'>

                                    <div className='flex flex-col justify-between items-start w-full py-2 rounded-lg'>

                                        <h1 className="text-black font-semibold text-[14px] lg:text-[14px] font-['Mona Sans'] leading-[20px] justify-start items-start p-2">{data.title}</h1>

                                        <h1 className="text-black font-light text-[14px]  leading-8 justify-start font-['Mona Sans']  items-start p-2">{data.time}</h1>

                                    </div>

                                </div>

                            </div >
                        </>

                    );
                })}
                </div>

            </div>



        </div>
    )
}

export default EventsBox