import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

const LastInteractionsChart = () => {

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const data = {
            labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],

            datasets: [
                {
                    type: 'bar',
                    label: 'Dataset 1',
                    backgroundColor: '#5B97FB',
                    borderRadius: 5,
                    data: [50, 25, 12, 48, 90, 76, 42]
                },
                {
                    type: 'bar',
                    label: 'Dataset 2',
                    backgroundColor: '#32D66E',
                    borderRadius: 5,
                    data: [21, 84, 24, 75, 37, 65, 34]
                },
                {
                    type: 'bar',
                    label: 'Dataset 3',
                    backgroundColor: '#996EFA',
                    borderRadius: 5,
                    data: [41, 52, 24, 74, 23, 21, 32]
                }
            ]
        };
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    label: {
                        display: false,
                    }
                },
                label: {
                    display: false,
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: '#334155'
                    },
                    grid: {
                        color: "#ffffff"
                    }
                   

                },
                y: {
                    stacked: true,
                    ticks: {
                        color: '#85878D'
                    },
                    grid: {
                        color: "#ffffff"
                    }
                   

                }
            }
        };
        setChartData(data);
        setChartOptions(options);
    }, []);


    return (

        <>
            <div className='flex flex-col justify-center items-start gap-1 p-2'>
                <h1 className="text-gray-950 text-opacity-70 text-[23px] font-bold font-['Mona Sans']">1h 47m</h1>
                <p className="text-gray-950 text-opacity-70 text-base font-normal font-['Mona Sans']">Today, 12 Decemeber</p>
            </div>

            <div className="w-full p-2">

                <div className="mt-2 mb-2 border-[0px]">
                    <p className="p-4"><Chart type="bar" data={chartData} options={chartOptions} /></p>

                </div>
            </div>

        </>

    );
}

export default LastInteractionsChart