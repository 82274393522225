import { React, useState } from 'react';
import { NavLink } from "react-router-dom";
import background from './images/insightbg.png';
import { CgInsights } from "react-icons/cg";
import { GoArrowRight, GoArrowLeft } from "react-icons/go";
import { GoArrowUpRight } from "react-icons/go";




const recentnews = [
  {
    path: "/details",
    title: "COP28: UAE planned to use climate talks to make oil deals",
    image: "../images/newscard1.png",
    id: 1,
    percent: "+78%",
    des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur molestie porta euismod...",
  },
  {
    path: "/details",
    title: "2023 NBA bets, lines and stats for Wednesday",
    image: "../images/newscard2.png",
    id: 2,
    percent: "+78%",
    des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur molestie porta euismod...",
  },

  {
    path: "/details",
    title: "Tyson Foods debuts highly automated $300M poultry plant in VA",
    image: "../images/newscard1.png",
    id: 3,
    percent: "+78%",
    des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur molestie porta euismod...",
  },
   {
    path: "/details",
    title: "2023 NBA bets, lines and stats for Wednesday",
    image: "../images/newscard2.png",
    id: 4,
    percent: "+78%",
    des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur molestie porta euismod...",
  },
  {
    path: "/details",
    title: "COP28: UAE planned to use climate talks to make oil deals",
    image: "../images/newscard1.png",
    id: 5,
    percent: "+78%",
    des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur molestie porta euismod...",
  },



]

const LatestNews = () => {

  const [current, setCurrent] = useState(0);
  const length = recentnews.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };
  if (!Array.isArray(recentnews) || recentnews.length <= 0) {
    return null;
  }

  return (

    <div className="rounded-xl border border-[#D7D7D7] w-full lg:w-[35%] p-6 h-auto  "
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',

      }} >
      <div className="flex flex-row justify-between items-start gap-4 pb-2">
        <div className='flex flex-row justify-center items-center rounded-full bg-white px-4 '>
          <div className='text-[25px] font-bold text-sky-600'>
            <CgInsights />
          </div>
          <h1 className="text-[14px] sm:text-[16px] font-bold p-2 text-sky-600">Insights</h1>
        </div>

        <form className="flex justify-center items-center text-black bg-white rounded-full">
          <select
            id="TotalCommandsIssued"
            name="TotalCommandsIssued"
            className="rounded-xl w-full border-none bg-transparent text-[14px]"
          >
            <option value="Weather update" className=" text-black text-[14px] font-normal">This Month</option>
            <option value="Daily News Digest" className=" text-black text-[14px] font-normal">This week</option>
            <option value="Search Assistant" className=" text-black text-[14px] font-normal">This Year</option>
          </select>
        </form>
      </div>

      <div className="flex flex-col justify-between items-center w-full bg-transparent rounded-xl gap-4 p-2 sm:p-6 ">

        {recentnews.map((slide, index) => {
          return (
            <div className={index === current ? 'flex flex-col justify-center items-start p-2 gap-4 ' : ' hidden'} key={index} >

             
             {index === current && (
              <div className='flex flex-row justify-start items-center gap-2 cursor-pointer rounded-3xl bg-white p-2 px-8'>
                <h1 className=" text-green-600 text-xl font-bold font-['Mona Sans'] leading-[30px] ">{slide.percent}  </h1>
                <GoArrowUpRight className='font-bold text-[20px]' />
              </div>
              )}
             

              {index === current && (
                <h1 className="text-slate-800 text-[20px] font-bold font-['Mona Sans'] leading-[30px] flex justify-center items-center"><NavLink to={`/${slide.path}`}>{slide.title} </NavLink></h1>
              )}

              {index === current && (
                <p className=" text-slate-800 text-[14px] font-normal font-['Plus Jakarta Sans'] leading-[25px]">{slide.des}</p>
              )}

               {index === current && (
                <p className=" text-black border-b-2 border-b-black  text-[16px] font-bold font-['Plus Jakarta Sans'] leading-[25px]"><NavLink to={`/${slide.path}`}>Read More </NavLink></p>
                                
              )}

            </div>
          );
        })}
        <div className='flex flex-row justify-between items-center w-full rounded-full bg-white p-2 '>
          <GoArrowLeft className='text-violet-500 text-[25px] font-bold cursor-pointer' onClick={prevSlide} />
          <GoArrowRight className='text-violet-500 text-[25px] font-bold cursor-pointer' onClick={nextSlide} />
        </div>
      </div >

    </div>


  )
}

export default LatestNews

