import React from 'react';
import background from './images/mediabg.png';

const LatestMedia = () => {
    return (
        <div className="flex flex-col rounded-[15px] border border-[#D7D7D7] w-full lg:w-[25%] p-6 bg-[#5A3472] gap-8 justify-between items-start h-auto sm:h-[300px]"
            style={{
                backgroundImage: `url(${background})`,
                backgroundPosition: 'bottom',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',

            }} >

            <div className='flex flex-col  justify-between items-start gap-2 w-full'>

                <h1 className="text-white text-[22px] font-bold font-['Mona Sans']">Latest Media</h1>
                <h3 className="text-white text-[16px] font-normal font-['Mona Sans']">30 media files</h3>

            </div>
        </div>
    )
}

export default LatestMedia