import React from 'react';
import { NavLink } from "react-router-dom";


const Workflows = [
  {
    title: "Tell me a fun fact",
    des: "Honey never spoils! Archaeologists have found pots of honey in ancient Egyptian tombs that are over 3,000 years old and still perfectly edible. The acidity, low moisture content, and natural preservatives in honey make it resistant to bacteria and spoilage.",
    link: "workflows/weather-update",

  },
  {
    title: "Tell me a fun fact",
    des: "Honey never spoils! Archaeologists have found pots of honey in ancient Egyptian tombs that are over 3,000 years old and still perfectly edible. The acidity, low moisture content, and natural preservatives in honey make it resistant to bacteria and spoilage.",
    link: "workflows/weather-update",

  },
  {
    title: "Tell me a fun fact",
    des: "Honey never spoils! Archaeologists have found pots of honey in ancient Egyptian tombs that are over 3,000 years old and still perfectly edible. The acidity, low moisture content, and natural preservatives in honey make it resistant to bacteria and spoilage.",
    link: "workflows/weather-update",

  },
  {
    title: "Tell me a fun fact",
    des: "Honey never spoils! Archaeologists have found pots of honey in ancient Egyptian tombs that are over 3,000 years old and still perfectly edible. The acidity, low moisture content, and natural preservatives in honey make it resistant to bacteria and spoilage.",
    link: "workflows/weather-update",

  },

]


const SophiaWorkedFlows = () => {
  return (
    <div className="flex flex-col gap-8 lg:flex-row justify-stretch items-center lg:gap-14 ">

      <div className="grid grid-cols-1 md:grid-cols-2 mt-2 mb-2 w-full gap-2 md:gap-4 ">

        {Workflows.map((wflows, i) => {
          return (

            <>

              <div className='flex flex-col justify-between items-start gap-4 shadow-md p-2 border-[1px] border-[#E2E8F0] rounded-[14px]'>

                <div className='flex flex-col justify-between items-start gap-2 w-full'>

                  <p className="text-[#A1A1A1] text-light text-[14px]">{wflows.title}</p>

                  <h1 className='text-[#4B5255] font-normal text-[14px] leading-6 justify-start items-start hover:text-[#111111]'>
                    <NavLink to={`/${wflows.link}`}>
                      {wflows.des}
                    </NavLink>
                  </h1>

                </div>

                <div className="flex flex-row justify-between items-start w-full gap-2">

                  <div className='w-full'>
                    <NavLink to={`/${wflows.link}`}>
                      <h1 className=" text-violet-500 text-[14px] font-bold">Read More</h1>
                    </NavLink>

                  </div>

                </div>



              </div>




            </>

          );
        })}

      </div>


    </div>
  )
}

export default SophiaWorkedFlows