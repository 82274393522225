import { NavLink, Link, Navigate } from "react-router-dom";
import { FaBars, FaHome, FaLock, FaMoneyBill, FaUser } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa6";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { PiChatCenteredTextThin } from "react-icons/pi";
import { PiPlusCircleThin } from "react-icons/pi";
import { BiCog } from "react-icons/bi";
import { GoWorkflow } from "react-icons/go";
import { BiSupport } from "react-icons/bi";
import { AnimatePresence, motion } from "framer-motion";
import { HiPlus } from "react-icons/hi2";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import SidebarMenu from "./SidebarMenu";
import UpgradeSubsciptionbox from "../components/extra-components/UpgradeSubsciptionbox";
import { Button, Checkbox, Label, Modal, TextInput } from 'flowbite-react';
import { useState } from 'react';

const routes = [
  {
    disabled: false,
    path: "/dashboard",
    name: "Home",
    icon: <FaHome />,
  },
  {
    disabled: false,
    path: "/global_features",
    name: "Global Features",
    icon: <FaRegUser />,
    subRoutes: [
      {
        path: "/settings/profile",
        name: "Profile ",
        icon: <FaUser />,
      },
      {
        path: "/settings/test",
        name: "Menu 1",
        icon: <FaLock />,
      },
      {
        path: "/settings/menu",
        name: "Menu 2",
        icon: <FaMoneyBill />,
      },
    ],
  },

  {
    disabled: false,
    path: "/workflows",
    name: "Workflows",
    icon: <GoWorkflow />,
  },
  {
    disabled: false,
    path: "/workspace",
    name: "Workspace",
    icon: <HiOutlineClipboardDocumentList />,
  },

  {
    disabled: false,
    path: "/help",
    name: "Support",
    icon: <BiSupport />,
  },
  {
    disabled: false,
    path: "/settings",
    name: "Settings",
    icon: <BiCog />,
  },


];

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const [openModal, setOpenModal] = useState(false);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };
  const navigateTo = (route) => {
    if (route.disabled) {
      return
    } else {
      <Navigate to={route.path} />
    }
  }
  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "240px" : "70px",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={'sidebar' }
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  <Link
                    to="/dashboard"
                  >
                    <img className="w-[150px]" src="/images/asksophia-logo.png" alt="asksophia" />
                  </Link>
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div>



          <section className="routes flex flex-col">
            {routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }

              return (

                <NavLink
                  to={route.disabled ? '#' : route.path}
                  //  onClick={()=>navigateTo(route)}
                  key={index}
                  // className="link "
                  className={`link ${route.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                  // activeClassName="active"
                  activeClassName={route.disabled ? '' : 'active'}
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
            <div className="flex flex-row justify-evenly items-center">
              <div className="text-[#52525B] text-[25px] cursor-pointer font-bold">
                <HiOutlineClipboardDocumentList />
              </div>

              <div>
                <h1 className={isOpen ? "text-[#52525B] font-semibold" : " hidden"}>Workspace</h1>
              </div>
              <div className="text-[#52525B] text-[22px] cursor-pointer font-bold">
                <HiPlus onClick={() => setOpenModal(!openModal)} />
              </div>
              <div>
                <Modal show={openModal} size="md" popup onClose={() => setOpenModal(false)} >
                  <Modal.Header />
                  <Modal.Body>
                    <div className="space-y-6">
                      <h3 className="text-xl font-medium text-gray-900 dark:text-white p-2 pb-8">Add Your Workspace</h3>
                    </div>
                    <div>
                      <form className="flex flex-col md:flex-row justify-between items-start md:items-center gap-2 md:gap-1">
                        <input
                          placeholder="Enter Workspace Name"
                          className="border-[1px] shadow-md border-[#919192] rounded-lg px-1 py-2"

                        />
                        <button
                          type="submit"
                          className="shadow-md bg-violet-500 px-6 py-1 text-[20px] rounded-lg text-white"
                        >  Add
                        </button>
                      </form>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>


            <div>
              {isOpen ? <UpgradeSubsciptionbox /> : ''}
            </div>

            <div className="flex flex-col items-center justify-center gap-1">
              <img src="../images/sidebar-cartoon.png" alt="gallery" className="px-1" />
              <h1 className="text-black font-bold text-[14px] px-1 max-w-full">Troy’s Workspace</h1>
              <h3 className=" text-slate-600 text-normal px-1 max-w-full">usmanzafar089@gmail.com</h3>
            </div>

            <div className="flex justify-center items-center">
              <Link to="/logout" >
                <div>
                  <h1 className="text-zinc-700 text-[16px] font-bold font-['Plus Jakarta Sans']">Log out</h1>
                </div>
              </Link>
            </div>

          </section>

        </motion.div>

        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
