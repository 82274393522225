import React from "react";
import { IoNewspaperSharp } from "react-icons/io5";
import { BsBookmarkStar } from "react-icons/bs";

const SophiaSuggestions = () => {
  return (
    <div className="rounded-xl border border-[#D7D7D7] w-full md:w-1/2 p-6">

      <div className="flex flex-row justify-start items-center gap-4 mb-[50px]">
        <h1 className="text-[22px] text-black font-bold leading-6 font-sans">
          Sophia's Suggestions
        </h1>
      </div>

      <div className="flex flex-col justify-between items-start place-content-center gap-6">

        <div className="text-[25px] text-orange-500">
          <IoNewspaperSharp />
        </div>

        <h1 className="text-[18px] text-black font-bold leading-6 font-sans">
          AI Suggestions
        </h1>

        <div className="flex flex-col justify-between items-start gap-4">
          <h3 className="text-[14px] text-black font-semibold font-sans leading-normal">You've been looking up a lot of tech news.</h3>
          <h1 className="text-[17px] text-black font-bold font-sans leading-normal"> Would you like to turn on daily tech briefings?</h1>     
        </div>

        <div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#22C55E] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#22C55E]">
            </div>
          </label>
        </div>

      </div>

    </div>
  );
};

export default SophiaSuggestions;
