import React from 'react';
import { VscGraph } from "react-icons/vsc";
import { CiTimer } from "react-icons/ci";

const DataPolicey = () => {
    return (
        <div className="p-4 flex flex-col gap-2">
            <h1 className="text-[24px] font-medium leading-7 p-4">
                Data Usage Policy
            </h1>
            <p className="text-[16px] font-light  leading-5 p-4">
                We respect your privacy. Learn how we use your data.
            </p>

            <h1 className="text-[16px] font-light leading-5 pl-4">
                Dear User,{" "}
            </h1>
            <div className="flex flex-col gap-2 text-[16px] font-light leading-6 pl-4 text-left">
                At AskSophia, we believe that your data is yours - and you deserve
                the utmost respect when it comes to privacy and use. Our promise
                to you is simple:
                <p>* Personalization Without Compromise: We only
                    use your data to make AskSophia work better for you. Think of it
                    as a custom-fit digital suit, tailored just for you, using your
                    own preferences and needs as the pattern.</p>

                <p>* Safety First: Your
                    data is stored securely, guarded like the treasure it is. We
                    protect it against pirates (hackers) and only you have the map to
                    where it's buried.</p>

                <p>* Transparency Always: No secret handshakes or
                    hidden agendas here. We’ll always tell you what data we're
                    collecting and why. </p>

                <p>Remember, you're the captain of this ship. You
                    can change your settings or sail away (delete your data) whenever
                    you want. </p>
                <p>Your trust is our treasure, and we're committed to
                    keeping it safe.</p>

                <p>Yours digitally, </p>
                <p>The AskSophia Team</p>
            </div>

            <h1 className="text-[16px] leading-5 font-semibold p-4">
                Link to full policy
            </h1>
            <p className="text-[16px] leading-5 font-light  pl-4 text-[#0EA5E9]">
                {" "}
                https://www.tlinkexample.com/lorem/consectetur/adipis
            </p>

            <div className="flex flex-row justify-between items-center gap-2">
                <div className="flex flex-col justify-between items-center ">

                    <div className="flex flex-row justify-between gap-4 items-center w-full">
                        <div className="px-4 p-2 font-bold text-[#15803D] text-[30px]">
                            <VscGraph />
                        </div>
                        <div>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" value="" className="sr-only peer" />
                                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#22C55E] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#22C55E]">
                                </div>
                            </label>
                        </div>
                    </div>

                    <div>
                        <h1 className="text-[20px] leading-5 font-bold p-4 text-left">Analytics</h1>
                        <p className="text-[14px] leading-5 font-light  pl-4 text-black">
                            Do you want to opt-out to analytics?
                        </p>
                    </div>

                </div>

                <div className="flex flex-col justify-between items-center ">

                    <div className="flex flex-row justify-between gap-4 items-center w-full">
                        <div className="px-4 p-2 font-bold text-[#15803D] text-[30px]">
                            <CiTimer />
                        </div>
                        <div className='text-[#15803D] font-bold text-[13px] sm:text-[18px]'>
                            <h1>2 hours</h1>
                        </div>
                    </div>

                    <div>
                        <h1 className="text-[16px ]sm:text-[20px] leading-5 font-bold p-4 text-left">Time Saved</h1>
                        <p className="text-[14px] leading-5 font-light  pl-4 text-black">
                          Average time saved this week
                        </p>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default DataPolicey