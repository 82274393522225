import React from "react";
import { useState } from "react";
import { FaMicrophoneAlt } from "react-icons/fa";
import { Tooltip } from 'flowbite-react';
import Addworkspace from "./Openchatbox";

const ChatBottomIcon = () => {

  const [showComponent, setShowComponent] = useState(false);


  const handleClick = event => {

    setShowComponent(prev => !prev);

  };

 


return (
  <>
    <div class="relative flex justify-end my-[20px] rounded-full bg-[#8B5CF6] ">

      <div className="fixed right-[80px] bottom-8 bg-[#8B5CF6] rounded-full px-4 py-2">

        <div className="flex flex-row gap-2 justify-center items-center">

          <div className="flex justify-center items-center p-2">
            <span className="rounded-full bg-white text-white  z-10">
              <FaMicrophoneAlt className="rounded-full text-black text-[30px] cursor-pointer p-1" />
            </span>
          </div>



          <div className="text-white text-[18px] font-bold font-['Mona Sans'] cursor-pointer" onClick={handleClick} >
            <h1>Talk to Sophia</h1>
          </div>

        </div>

      </div>

    </div>



      <div className="fixed right-[60px] bottom-28 md:right-[80px] md:bottom-28 ">

        <div className="flex flex-row gap-2 justify-center items-center">
          <div>
          {showComponent && <Addworkspace />}
          </div>

        </div>

      </div>



  </>
);
};

export default ChatBottomIcon;
