import React from 'react';
import { TiWeatherDownpour } from "react-icons/ti";
import { RiMapPinLine } from "react-icons/ri";
import { MdEventNote } from "react-icons/md";

const WeatherBox = () => {
    return (
        <div className="rounded-[22px] border border-[#D7D7D7] w-full lg:w-[35%] p-6 bg-amber-200 gap-8 justify-between items-center h-auto sm:h-[400px]">

            <div className='pb-6'>
                <div className='flex justify-start items-center'>
                    <img src="../images/sunny.png" alt="Weather image" />
                </div>

                <div className='text-[60px]'>
                    <h1 className="text-black  font-bold font-['Mona Sans']"> 13° </h1>
                </div>


                <div className='flex flex-row  justify-start items-center gap-2 p-2'>
                    <div className='text-[40px] font-bold'>
                        <TiWeatherDownpour />
                    </div>
                    <div className=' text-[16px]'>
                        <h1 className="text-zinc-800 font-medium font-['Mona Sans'] tracking-wide">Rainy Storm Clouds</h1>
                    </div>
                </div>

            </div>


            <div>

                <div className='flex flex-row justify-start items-center gap-4 p-2'>
                    <div className='text-[25px] font-medium'>
                        <RiMapPinLine />
                    </div>
                    <h1 className="text-gray-950 text-[14px] font-medium font-['Mona Sans'] tracking-wide">California, CA</h1>
                </div>

                <div className='flex flex-row justify-start items-center gap-4 p-2'>

                    <div className='text-[25px] font-medium'>
                        <MdEventNote />
                    </div>

                    <div className='flex flex-col sm:flex-row gap-1'>
                        <h1 className="text-gray-950 text-[14px] font-medium font-['Mona Sans']">Tuesday, 22 Nov, 2023  </h1>
                        <h1 className="text-gray-950 text-[14px] font-bold font-['Mona Sans']">5:45 PM </h1>
                    </div>

                </div>

            </div>


        </div>
    )
}

export default WeatherBox