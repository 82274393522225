import React from 'react';
import WeatherBox from './WeatherBox';
import EventsBox from './EventsBox';




const TopComponents = () => {
  return (

    <div className="rounded-xl border border-[#f5f5f5] w-full lg:w-[75%] bg-[#E5F4FF]">

      <div className="flex flex-col  lg:flex-row justify-between items-center w-full rounded-xl gap-4 bg-[#E5F4FF]">
       <WeatherBox />
       <EventsBox />
      </div >

    </div>

  )
}

export default TopComponents

