import React from 'react';
import { FaStar } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { GiSonicShoes } from "react-icons/gi";
import { GiClothes } from "react-icons/gi";
import { FcElectronics } from "react-icons/fc";
import { NavLink } from 'react-router-dom';


const onlineshoppingcard = [
    {
        path: "/details",
        title: "Shoes",
        percent: "+78%",
        items: "36 Products",
        image: "./images/bg_3.png",

    },
    {
        path: "/details",
        title: "Clothes",
        percent: "+78%",
        items: "36 Products",
        image: "./images/bg_1.png",

    },

    {
        path: "/details",
        title: "Electronics",
        percent: "+78%",
        items: "36 Products",
        image: "./images/bg_1.png",

    },
    {
        path: "/details",
        title: "Electronics",
        percent: "+78%",
        items: "36 Products",
        image: "./images/bg_4.png",

    },

]

const OnlineShoppingbox = () => {
    return (

        <div className="rounded-xl border border-[#D7D7D7] w-full lg:w-[35%] p-6 h-auto" >
            <div className="flex flex-row justify-between items-start gap-4">
                <h1 className="text-[16px] sm:text-[22px] font-bold p-2">Top Selling Products Online 🛍️ </h1>
                <div className="flex justify-center items-center text-black bg-transparent">
                    <NavLink to={"/view-more"}>
                        <h1 className='text-[#A1A1A1] font-semibold text-[14px] hover:text-black'>View More</h1>
                    </NavLink>

                </div>
            </div>


            <div className="flex flex-col justify-between items-start w-full bg-white rounded-xl gap-4 p-2 ">

                {onlineshoppingcard.map((wflows, i) => {
                    return (

                        <>
                            <div className='flex flex-row justify-around items-center gap-4 shadow-md p-2 w-full'>

                                <div className='flex items-center justify-center text-[#8300D38A] text-[30px] p-2 shadow-md h-[80px ] w-[90px]'>
                                    <img src={wflows.image} alt='' className='h-[53px ] w-[57px] items-center justify-start rounded-[10px]' />
                                </div>

                                <div className='flex flex-col justify-between items-start gap-2 w-full'>
                                    <h1 className='text-black font-bold text-[18px] leading-6 justify-start items-start hover:text-[#4B5255]'>
                                        <NavLink to={`/${wflows.path}`}>
                                            {wflows.title}
                                        </NavLink>
                                    </h1>
                                    <h1 className='text-[14px] font-normal text-[#64748B]'> {wflows.items}</h1>
                                </div>

                                <div className=' items-start text-green-700 font-bold'>
                                    {wflows.percent}
                                </div>

                                <div className=" text-black cursor-pointer border-1 border-[#585555] rounded-lg p-2 shadow-lg">

                                    <NavLink to={`/${wflows.path}`}>
                                        <IoIosArrowForward />
                                    </NavLink>
                                </div>

                            </div>


                        </>

                    );
                })}

            </div >



        </div>

    )
}

export default OnlineShoppingbox