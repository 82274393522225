import React from 'react';
import background from '../images/upgrade.png';

const UpgradeSubsciptionbox = () => {
    return (
        <div className='w-full p-4 gap-8 rounded-3xl'
            style={{
                backgroundImage: `url(${background})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',

            }}>

            <div className='flex flex-row gap-2 justify-between items-start pb-6 pt-4'>
                <h1 className='font-bold'>Upgrade Subscription</h1>
                <h3 className='font-extrabold text-[18px]'>$556/m</h3>
            </div>

            <div className='flex justify-center items-center px-2 py-4 pb-[150px]'>
                <button className='bg-white text-black text-[10px] sm:text-[15px] font-bold rounded-full px-4 py-1 sm:px-6 sm:py-2'>Upgrade Now</button>
            </div>

        </div>
    )
}

export default UpgradeSubsciptionbox