import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';



const TotalCommandsIssued = () => {

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {

      const data = {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
          datasets: [
              {
                  label: 'Dataset',
                  data: [70, 61, 62, 63, 54, 65, 59],
                  fill: true,
                  backgroundColor: 'rgba(118, 93, 255, .2)',
                  borderColor:'#292D30',
                  borderWidth:4,
                  tension: 0.4
              },
          ]
      };
      const options = {
          maintainAspectRatio: false,
          responsive: true,
          aspectRatio: 0.7,
          plugins: {
              tooltips: {
                  mode: 'index',
                  intersect: false
              },
              legend: {
                  labels: {
                      color: '#000000'
                  }
              }
          },
          scales: {
              x: {
                  ticks: {
                      color: '#8D9092'
                  },
                  grid: {
                      color: "#ffffff"
                  }
                
              },
              y: {
                  ticks: {
                      color: '#8D9092'
                  },
                  grid: {
                      color: "#ffffff"
                  }
               
              }
          }
      };

      setChartData(data);
      setChartOptions(options);
  }, []);
  
  
    return (
      <div className="w-full p-2">
  
        <div className="mt-2 mb-2 border-[0px]">
          <p className="p-2"><Chart type="line" data={chartData} options={chartOptions}/></p>
        </div>
      </div>
    );
  };

export default TotalCommandsIssued
