import React from "react";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { HiOutlineNewspaper } from "react-icons/hi2";
import { BsBagCheck } from "react-icons/bs";
import { TbSocial } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";


const Workflows = [
  {
    title: "You checked the weather",
    time: "Today, 09:00 AM",
    link: "workflows",
    icon: <TiWeatherPartlySunny />,
  },
  {
    title: "Brief Of Your Latest News",
    time: "Today, 09:00 AM",
    link: "workflows/weather-update",
    icon: <HiOutlineNewspaper />,
  },

  {
    title: "Online Shopping",
    time: "Today, 09:00 AM",
    link: "workflows/online-shopping",
    icon: <BsBagCheck />,
  },
  {
    title: "Social Media Recap",
    time: "Today, 09:00 AM",
    link: "workflows/social-media-recap",
    icon: <TbSocial />,
  },

]


const RecentActivities = () => {
  return (
    <div className="flex flex-col gap-8 lg:flex-row justify-stretch items-center lg:gap-14 ">

      <div className="grid grid-cols-1 md:grid-cols-2 mt-2 mb-2 w-full gap-2 md:gap-4 ">

        {Workflows.map((wflows, i) => {
          return (

            <>

              <div className='flex flex-col justify-between items-start gap-4 shadow-md p-2 border-[1px] border-[#E2E8F0] rounded-[14px]'>

                <div className='flex items-center justify-center text-[#8300D38A] text-[30px] rounded-full  bg-[#FED7AA] p-4 shadow-md'>
                  {wflows.icon}
                </div>

                <div className='flex flex-col justify-between items-start gap-2 w-full'>

                  <p className="text-[#A1A1A1] text-light text-[12px]">{wflows.time}</p>

                  <h1 className='text-[#4B5255] font-bold text-[14px] leading-6 justify-start items-start hover:text-[#111111]'>
                    <NavLink to={`/${wflows.link}`}>
                      {wflows.title}
                    </NavLink>
                  </h1>

                </div>

                <div className="flex flex-row justify-between items-center w-full gap-2">
                  <div className='w-[80%]'>
                    <ProgressBar
                      completed="50"
                      bgColor="#FDBA74"
                      baseBgColor="white"
                      height="15px"
                      isLabelVisible={false}
                    />
                  </div>
                  <div className='w-[20%]'>
                    <h1 className="text-black text-[14px]">2 Min</h1>
                  </div>

                </div>



              </div>




            </>

          );
        })}

      </div>


    </div>
  );
};

export default RecentActivities;
