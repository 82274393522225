import React, { useState } from "react";
import { VscGraph } from "react-icons/vsc";
import MainSocialbox from "./extra-components/MainSocialbox";
import GraphSocialbox from "./extra-components/GraphSocialbox";


const SocialMediaRecap = () => {

  const [visible, Isvisible] = useState(false);


  const HandleVisible = () => {
    Isvisible(!visible);
  }


  return (
    <div className="rounded-xl border border-[#D7D7D7] w-full  p-6">
      <div className="flex flex-row justify-between items-start gap-4">

        <h1 className="text-[14px] sm:text-[20px] font-bold p-2">Social Media Recap</h1>

        <div className="flex flex-row gap-2 justify-between items-center">
          <form className="flex justify-center items-center text-black bg-transparent">
            <select
              id="TotalCommandsIssued"
              name="TotalCommandsIssued"
              className="rounded-xl w-full border-none bg-transparent text-[14px]"
            >
              <option value="Weather update" className=" text-black text-[14px] font-normal">This Month</option>
              <option value="Daily News Digest" className=" text-black text-[14px] font-normal">This week</option>
              <option value="Search Assistant" className=" text-black text-[14px] font-normal">This Year</option>
            </select>
          </form>

          <div className={visible ? "font-bold text-[20px] bg-violet-500 text-white rounded-lg p-2 cursor-pointer" : "font-bold text-[20px] bg-slate-400 text-black rounded-lg p-2 cursor-pointer"} onClick={HandleVisible}>
            <VscGraph />
          </div>
        </div>

      </div>

      <div className="mt-5 mb-5">

        <div> {visible ? <GraphSocialbox /> : <MainSocialbox />}  </div>

      </div>
    </div>
  );
};

export default SocialMediaRecap;
