import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';



const TimeSpentChart = () => {

  const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {

        const data = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
            datasets: [
                {
                    label: 'First Dataset',
                    data: [65, 59, 80, 81, 56, 55, 40],
                    fill: false,
                    borderColor:'#5B3BFF',
                    borderWidth:4,
                    tension: 0.4
                },
                {
                    label: 'Second Dataset',
                    data: [28, 48, 40, 19, 86, 27, 90],
                    fill: false,
                    borderColor: '#C893FD',
                    borderWidth:4,
                    tension: 0.4
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            responsive: true,
            aspectRatio: 0.7,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: '#000000'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#8D9092'
                    },
                    grid: {
                        color: "#ffffff"
                    }
                  
                },
                y: {
                    ticks: {
                        color: '#8D9092'
                    },
                    grid: {
                        color: "#ffffff"
                    }
                 
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);
    
    
      return (
        <div className="w-full p-2">
    
          <div className="mt-2 mb-2 border-[0px]">
            <p className="p-2"><Chart type="line" data={chartData} options={chartOptions}/></p>
          </div>
        </div>
      );
  };

export default TimeSpentChart
