import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../App";
import React from "react";
import { LuShoppingCart } from "react-icons/lu";
import { IoIosNotificationsOutline } from "react-icons/io";

import { CiSearch } from "react-icons/ci";

const Header = () => {

  return (
    <div className="w-full border-b-1 border-[#858585] bg-black p-3 mb-3">

      <div className="flex  flex-col-reverse md:flex-row justify-between w-full">
        <div className=" flex-col p-[2px] gap-0 md:flex md:flex-row justify-start items-start sm:gap-6 sm:p-2">
          <div className="flex flex-col">
            <h2 className="text-[14px] leading-4 md:text[16px] text-white md:leading-[25px] font-normal place-content-start">
              Saturday, November 18
            </h2>
            <h1 className="text-white text-[20px] font-bold font-['Plus Jakarta Sans']">
              Good afternoon, Usman
            </h1>
          </div>
        </div>

        <div className="flex flex-col  sm:flex-row gap-2 justify-between items-center  sm:gap-4 md:justify-center md:items-center">
          <div className="flex flex-col sm:flex-row justify-start items-center">

            <div className="flex flex-row justify-center items-center gap-2 bg-zinc-800 border border-[#858585] text-[#858585] rounded-lg text-[12px] md:text-[20px] p-1 m-1 cursor-pointer">

              <div className="text-[25px]">
                <CiSearch />
              </div>

              <div>
                <form className="flex gap-4">
                  <input type="text" name="search" placeholder="Search Something..." className="bg-transparent border-none text-[14px] text-zinc-300" />
                  <input type="submit" name="submit" value="Search" className=" bg-black border-[1px] border-[#2f2f31] text-[14px] text-zinc-300 p-1 px-4 rounded-md cursor-pointer" />
                </form>
              </div>

            </div>

            <div className="flex flex-row justify-center items-center gap-2   text-[12px] md:text-[20px]  ">

              <div className="flex flex-row justify-center items-center gap-2  cursor-pointer text-zinc-300 rounded-lg border border-[#858585] p-3 m-1">

                <div className="text-[20px] text-white">
                  <LuShoppingCart />
                </div>

                <div>
                  <h1 className="text-[14px] text-zinc-300">Shopping Cart</h1>
                </div>
              </div>


              <div className="flex flex-row  items-center justify-center gap-4">
                <span className="text-[40px] text-white cursor-pointer">
                  <IoIosNotificationsOutline />
                </span>
              </div>

            </div>

          </div>


        </div>
      </div>
    </div>
  );
};
export default Header;
