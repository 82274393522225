import React from "react";
import { FaMicrophoneAlt } from "react-icons/fa";
import { FaPaperPlane } from "react-icons/fa6";

const QuickActions = () => {
  return (
    <div className="flex flex-col justify-between items-center rounded-[15px] p-2  border border-[#ffffff] w-full  lg:w-[25%] bg-white gap-4 h-auto sm:h-[400px]">

      <div className="flex flex-col justify-around gap-4 w-full">
        <h1 className="text-[26px] font-bold p-4 text-gray-950 font-['Mona Sans'] text-center">
          <span className="text-violet-500">Feeling </span>good and ready to rock it, Usman?</h1>
        <h3 className="text-center text-slate-500 text-[20px] font-light font-['Mona Sans'] leading-[25px]">Choose a workflow & Start or start with Voice commands</h3>
      </div>


      <div className="flex w-full ">
        <div className="flex flex-col justify-around gap-4 w-full">
          <form className="flex justify-around items-center gap-4 p-4">
            <select id="workflow" name="workflow" className="focus:outline-none rounded-xl w-full border-none text-[16px] font-normal">
              <option value="Weather update">Weather Update</option>
              <option value="Daily News Digest">Daily News Digest</option>
              <option value="Search Assistant">Search Assistant</option>
              <option value="Social Media Recap">Social Media Recap</option>
            </select>
          </form>
        </div>
      </div>

      <div className="flex flex-row gap-2 p-4  justify-between  items-center w-full">
        <div className="flex flex-row justify-center items-center gap-2">
          <span><FaMicrophoneAlt className="rounded-full text-[#8B5CF6] text-[40px] p-2 cursor-pointer" /></span>
          <h2>Voice Activation</h2>
        </div>

        <div className="flex flex-row gap-1 justify-center items-center rounded-full bg-violet-500 px-6 py-3">
          <FaPaperPlane className="text-[#BDA3FB] text-[20px]" />
          <h1 className="text-white text-sm font-bold font-['Mona Sans']">Start</h1>
          
        </div>

      </div>

    </div>
  );
};

export default QuickActions;
