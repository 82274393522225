import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import ProgressBar from "@ramonak/react-progress-bar";

const social = [
    {

        title: "Facebook",
        icon: <FaFacebook />,
        id: 1,
        views: "23,000 Views",
        percent: 50,

    },
    {

        title: "Twitter",
        icon: <FaTwitter />,
        id: 2,
        views: "23,000 Views",
        percent: 30,

    },

    {

        title: "Linkedin",
        icon: <FaLinkedinIn />,
        id: 3,
        views: "23,000 Views",
        percent: 90,
    },
    {

        title: "Instagram",
        icon: <FaInstagram />,
        id: 4,
        views: "23,000 Views",
        percent: 70,

    },
    {
        title: "TikTok",
        icon: <FaTiktok />,
        id: 5,
        views: "23,000 Views",
        percent: 20,

    },



]

const GraphSocialbox = () => {

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {

        const data = {
            labels: ['Followers', 'Views'],
            datasets: [
                {
                    data: [40, 50],
                    backgroundColor: [     
                        '#C4B5FD',
                        '#FDBA74',
                    ],
                    hoverBackgroundColor: [
                        '#000000',
                        '#000000'
                    ]
                }
            ]
        };
        const options = {
            responsive: true,
            cutout: '50%'
        };

        setChartData(data);
        setChartOptions(options);
    }, []);
    return (
        <div className='flex flex-col md:flex-row justify-start items-center gap-4 w-full'>

            <div className="flex flex-row justify-start items-center gap-2 w-full">

                <div className="flex flex-col mt-2 mb-2 w-full gap-2  ">

                    {social.map((data, i) => {
                        return (
                            <>
                                <div className='flex flex-row justify-start items-center gap-1'>
                                    <div className='flex flex-col justify-between items-start gap-4  p-2 w-full '>

                                        <div className='flex flex-row items-center justify-start text-black text-[30px] p-4 w-full gap-4'>

                                            <div className='text-[25px]'>
                                                {data.icon}
                                            </div>

                                            <div className='flex flex-col justify-center items-start gap-1 '>
                                                <h1 className='text-[#4B5255] font-bold text-[16px] leading-6 justify-start items-start hover:text-[#111111]'>

                                                    {data.title}

                                                </h1>
                                                <p className="text-[#52525B] text-light text-[14px]">{data.views}</p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className='w-full p-2'>
                                        <ProgressBar
                                            completed={data.percent}
                                            bgColor="#FFC369"
                                            baseBgColor="#CBCBCB"
                                            height="12px"
                                            isLabelVisible={false}

                                        />

                                    </div>
                                </div>

                            </>
                        );
                    })}

                </div>

            </div>

            <div className='flex justify-center items-center w-full'>
                <Chart type="doughnut" data={chartData} options={chartOptions} className="w-full md:w-[80%]" />
            </div>
        </div>
    )
}

export default GraphSocialbox