import React from 'react';
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const MainSocialbox = () => {
    return (
        <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Network
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Visitors
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Followers
                        </th>
                        <th scope="col" className="px-6 py-3">
                            LIKES
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex flex-row justify-start items-center gap-2">
                            <FaFacebook className="text-[20px] font-bold" /> Facebook
                        </th>
                        <td className="px-6 py-4">
                            34677
                        </td>
                        <td className="px-6 py-4">
                            658
                        </td>
                        <td className="px-6 py-4">
                            554
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex flex-row justify-start items-center gap-2">
                            <FaTwitter className="text-[20px] font-bold" /> Twitter
                        </th>
                        <td className="px-6 py-4">
                            3245
                        </td>
                        <td className="px-6 py-4">
                            2344
                        </td>
                        <td className="px-6 py-4">
                            1999
                        </td>
                    </tr>
                    <tr className="bg-white dark:bg-gray-800">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex flex-row justify-start items-center gap-2">
                            <FaLinkedinIn className="text-[20px] font-bold" /> Linkedin
                        </th>
                        <td className="px-6 py-4">
                            678
                        </td>
                        <td className="px-6 py-4">
                            345
                        </td>
                        <td className="px-6 py-4">
                            656
                        </td>
                    </tr>
                    <tr className="bg-white dark:bg-gray-800">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex flex-row justify-start items-center gap-2">
                            <FaInstagram className="text-[20px] font-bold" /> Instagram
                        </th>
                        <td className="px-6 py-4">
                            678
                        </td>
                        <td className="px-6 py-4">
                            345
                        </td>
                        <td className="px-6 py-4">
                            656
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default MainSocialbox