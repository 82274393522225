import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';



const MostUsedWorkflows= () => {

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
      const data = {
          labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],

          datasets: [
              {
                type: 'bar',
                label: 'Free',
                backgroundColor: '#962DFF',
                borderRadius: 5,
                data: [41, 34, 24, 75, 47, 95, 24]
            },
            {
              type: 'bar',
              label: 'Basic',
              backgroundColor: '#C893FD',
              borderRadius: 5,
              data: [21, 34, 24, 55, 47, 65, 34]
          },
              {
                  type: 'bar',
                  label: 'Pro',
                  backgroundColor: '#E0C6FD',
                  borderRadius: 5,
                  data: [11, 54, 24, 75, 57, 65, 34]
              },
              {
                  type: 'bar',
                  label: 'Enterprise',
                  backgroundColor: '#F0E5FC',
                  borderRadius: 5,
                  data: [41, 52, 24, 74, 23, 21, 32]
              }
          ]
      };
      const options = {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 0.8,
          plugins: {
              tooltips: {
                  mode: 'index',
                  intersect: false
              },
              legend: {
                  label: {
                      display: false,
                  }
              },
              label: {
                  display: false,
              }
          },
          scales: {
              x: {
                  stacked: true,
                  ticks: {
                      color: '#334155'
                  },
                  grid: {
                      color: "#ffffff"
                  }
                 

              },
              y: {
                  stacked: true,
                  ticks: {
                      color: '#85878D'
                  },
                  grid: {
                      color: "#ffffff"
                  }
                 

              }
          }
      };
      setChartData(data);
      setChartOptions(options);
  }, []);


  return (

      <>

          <div className="w-full p-2">

              <div className="mt-2 mb-2 border-[0px]">
                  <p className="p-4"><Chart type="bar" data={chartData} options={chartOptions} /></p>

              </div>
          </div>

      </>

  );
  };

export default MostUsedWorkflows
