import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import { IoIosClose } from "react-icons/io";
import MetricSidebarTab from "../components/MetricSidebarTab";
import img from "../components/images/Subtract.png";


const MetricSidebar = ({ children }) => {


  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
    <div className=" bg-transparent ">
      <img src={img} alt="sidebar_img" onClick={() => setIsOpen(true)} className=" h-auto bg-transparent" />
    </div>
      

      <main
        className={
          " fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " +
          (isOpen
            ? " transition-opacity opacity-100 duration-500 translate-x-0  "
            : " transition-all delay-500 opacity-0 translate-x-full  ")
        }
      >
        <section
          className={
            " w-screen max-w-lg md:max-w-xl right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform border-[10px] rounded-sm border-[#2E1065] " +
            (isOpen ? " translate-x-0 " : " translate-x-full ")
          }
        >
          <article className="relative w-screen max-w-lg md:max-w-xl pb-10 flex flex-col space-y-6 overflow-y-scroll h-full">

            <div className="flex flex-row justify-start items-center gap-8 ">
              <div>
                <header className="p-4 font-bold text-lg">User Metrics Dashboard</header>
              </div>
              <div onClick={() => setIsOpen(false)} className=" font-light text-[20px] bg-slate-400 rounded-sm p-2" >
              <IoIosClose />
              </div>
            </div>

            <div>
              <MetricSidebarTab />
            </div>
          </article>
        </section>
        <section
          className=" w-screen h-full cursor-pointer bg-[#000000c2] "
          onClick={() => {
            setIsOpen(false);
          }}
        ></section>
      </main>
    </>
  );
};

export default MetricSidebar;
