import React from 'react';
import background from './images/openbg.png';
import ProgressBar from "@ramonak/react-progress-bar";
const OpenProjects = () => {
    return (
        <div className="flex flex-col rounded-[15px] border border-[#D7D7D7] w-full lg:w-[50%] p-6 bg-[#5A3472] gap-8 justify-between items-start h-auto sm:h-[300px]"
            style={{
                backgroundImage: `url(${background})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',

            }} >

            <div className='flex flex-col  justify-between items-start gap-2 w-full'>

                <h1 className="text-white text-[22px] font-bold font-['Mona Sans']">Open Projects</h1>
                <h3 className="text-white text-[18px] font-semibold font-['Mona Sans']">30 tasks remaining</h3>

                <div className='w-[80%] sm:w-[60%]'>

                <ProgressBar 
                completed="60" 
                bgColor="#818CF8"
                baseBgColor="white"
                height="15px"
                isLabelVisible = {false}
                
                />
                </div>

            </div>



            <div className='flex flex-col justify-between items-start w-full'>


                <div className='rounded-full px-4 py-2 shadow-md border-[2px] border-white bg-white'>
                    <button className=" text-violet-500 text-[16px] font-bold font-['Mona Sans']">Completed Tasks</button>
                </div>

            </div>



        </div>
    )
}

export default OpenProjects