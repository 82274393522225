import React from 'react';
import { IoIosArrowForward } from "react-icons/io";
import { FiBarChart2 } from "react-icons/fi";
import { MdDoneAll } from "react-icons/md";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { HiOutlineNewspaper } from "react-icons/hi2";
import { BsBagCheck } from "react-icons/bs";
import { TbSocial } from "react-icons/tb";
import { NavLink } from "react-router-dom";


const Workflows = [
    {
      title: "All",
      link: "workflows",
      icon: <MdDoneAll />,
    },
    {
      title: "Weather Update",
      link: "workflows/weather-update",
      icon: <TiWeatherPartlySunny />,
    },
  
    {
      title: "Daily News Digest",
      link: "workflows/daily-news-digest",
      icon: <HiOutlineNewspaper />,
    },
    {
      title: "Online Shopping",
      link: "workflows/online-shopping",
      icon: <BsBagCheck />,
    },
    {
      title: "Social Media Recap",
      link: "workflows/social-media-recap",
      icon: <TbSocial />,
    },
    {
        title: "Weather Update",
        link: "workflows/weather-update",
        icon: <TiWeatherPartlySunny />,
      },
    
      {
        title: "Daily News Digest",
        link: "workflows/daily-news-digest",
        icon: <HiOutlineNewspaper />,
      },
      {
        title: "Online Shopping",
        link: "workflows/online-shopping",
        icon: <BsBagCheck />,
      },
  
  ]

const MostWorkedFlows = () => {
  return (
    
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

    {Workflows.map((wflows, i) => {
      return (

        <>
          <div className='flex flex-row justify-between items-center gap-4 shadow-md p-4'>

            <div className='flex items-center justify-center text-[#8300D38A] text-[30px] rounded-full  bg-[#FED7AA] p-2 shadow-md'>
              {wflows.icon}
            </div>

            <div className='flex flex-col justify-between items-start gap-2 w-full'>
              <h1 className='text-black font-bold text-[16px] leading-6 justify-start items-start hover:text-[#4B5255]'>
                <NavLink to={`/${wflows.link}`}>
                {wflows.title}
                </NavLink>
                
                
              
              </h1>
            </div>

            <div className=" text-black cursor-pointer border-1 border-[#585555] rounded-lg p-2 shadow-lg">
              
              <NavLink to={`/${wflows.link}`}>
              <IoIosArrowForward />
                </NavLink>
            </div>

          </div>


        </>

      );
    })}

  </div>
  )
}

export default MostWorkedFlows