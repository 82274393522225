import React, { useState } from "react";
import { Fragment } from 'react';
import { Tab } from '@headlessui/react';
import MostWorkedFlows from "./MostWorkedFlows";
import SophiaWorkedFlows from "./SophiaWorkedFlows";
import { VscGraph } from "react-icons/vsc";
import SophiaSuggestionsChart from "./extra-components/SophiaSuggestionsChart";



const MostWorkflowsUsed = () => {

  const [selected, Isselected] = useState(false);
  const [visible, Isvisible] = useState(false);

  const HandleClick = () => {
    Isselected(!selected);
  }

  const HandleVisible = () => {
    Isvisible(!visible);
  }

  return (
    <div className="rounded-xl border border-[#D7D7D7] w-full  p-6 ">
      <div className="flex flex-row justify-between items-start gap-4">
        <div>
          <h1 className="text-[22px] text-black font-bold leading-6">Most Used Workflows</h1>
        </div>

        <div className={ visible ? "font-bold text-[20px] bg-violet-500 text-white rounded-lg p-2 cursor-pointer":"font-bold text-[20px] bg-slate-400 text-black rounded-lg p-2 cursor-pointer"} onClick={HandleVisible} >
          <VscGraph  />
        </div>
      </div>

      <div className="m-5">

        <div>
          <Tab.Group>
            <div className="flex flex-col md:flex-row gap-12 p-4 " >
              <Tab.List>
                <Tab as={Fragment} onClick={HandleClick}>
                  {({ selected }) => (

                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected ? " text-white px-16 py-2 rounded-full  bg-violet-500 font-['Mona Sans']" : " bg-white text-[#A1A1A1] px-16 py-2 font-['Mona Sans']"
                      }
                    >
                      Most Used Workflows
                    </button>
                  )}
                </Tab>
                {/* ...  */}
              </Tab.List>

              <Tab.List>
                <Tab as={Fragment} onClick={HandleClick}>
                  {({ selected }) => (
                    /* Use the `selected` state to conditionally style the selected tab. */
                    <button
                      className={
                        selected ? " text-white px-16 py-2 rounded-full bg-violet-500 font-['Mona Sans']" : " bg-white text-[#A1A1A1] px-16 py-2 font-['Mona Sans']"
                      }
                    >
                      Sophia Suggestions
                    </button>
                  )}
                </Tab>
                {/* ...  */}
              </Tab.List>

            </div>

            <Tab.Panels>
              <Tab.Panel>


              <div> {visible ? <SophiaSuggestionsChart /> : <MostWorkedFlows />}  </div>


              </Tab.Panel>

            </Tab.Panels>

            <Tab.Panels>
              <Tab.Panel>

                <div><SophiaWorkedFlows /></div>


              </Tab.Panel>

            </Tab.Panels>
          </Tab.Group>


        </div>

      </div>
    </div>
  );
};

export default MostWorkflowsUsed;
